@import 'variables';
@import './base';

.notification {
  border-radius: 1rem;
  font-size: $font-size-default;
  min-height: 7.4rem;
  overflow: hidden;
  padding: 1.75rem 1.5rem 0.75rem 0;
  position: relative;
  word-break: break-word;
}

.notification--bottom-offset {
  margin-bottom: 6rem;
}

.notification--top-offset {
  margin-top: 3rem;
}

.notification--information {
  background-color: $notification-information-bg-color;
}

.notification--warning {
  background-color: $notification-warning-bg-color;
}

.notification--success {
  background-color: $notification-success-bg-color;
}

.notification--error {
  background-color: $notification-error-bg-color;
}

.notification--transparent {
  background-color: $notification-transparent-bg-color;
  border: 1px solid $notification-transparent-border-color;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.notification__title {
  font-weight: bold;
  margin-top: 0;
  color: white;
}

.notification__title,
.notification__description,
.notification__button.onboarding-button {
  margin-bottom: 1rem;
}

.notification__actions {
  .onboarding-button.notification__button,
  .onboarding-button.notification__button.icon-button {
    font-family: inherit;
    font-weight: bold;
    margin-right: 0.5em;
    margin-top: 0;
    min-width: 0;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    vertical-align: bottom;

    &--close {
      margin-bottom: 1rem;

      @include media('<notification-wide') {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
      }
    }
  }
}

.notification__icon-wrapper {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 4.4rem;
  margin-top: -2.4rem;
  justify-content: center;
  left: 1rem;
  position: absolute;
  top: 50%;
  width: 4.4rem;
}

.notification__body,
.notification__actions {
  padding-left: 6.3rem;

  .notification__no-icon & {
    padding-left: 2rem;
  }
}

.notification__actions {
  padding-top: 1rem;
}

.notification.notification--close-button:not(.notification--button) {
  .notification__actions {
    padding-left: 0;
    padding-bottom: 1rem;
  }

  .notification__button--close {
    display: block;
    margin: 0 auto;
  }
}

@include media('>=notification-wide') {
  .notification--wide {
    .notification__body {
      max-width: 327px;
      width: 100%;
    }

    .notification__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 1em;
    }
  }
}

@include media('<small') {
  .notification {
    padding: 1.5rem 1.5rem 0.5rem;
    max-width: 327px;
    width: 100%;
  }

  .notification__title {
    line-height: 18px;
    margin-bottom: 6px;
  }

  .notification__icon-wrapper {
    display: none;
    left: 1.5rem;
  }

  .notification__no-icon {
    .notification__body,
    .notification__actions {
      padding-left: 0.5rem;
    }
  }

  .notification__body,
  .notification__actions {
    padding-left: 0;
    width: 100%;
  }

  .notification__actions {
    .notification__button {
      width: calc(100% - #{$icon-button-width});
    }

    .notification__button:last-child {
      width: 100%;
    }
  }
}
