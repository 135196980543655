$primary-title-font: 'BebasNeue-Bold', sans-serif;

// Use font-weight 500 to actually get Gotham-Bold. If you use a font-weight larger than you will get Gotham-Ultra
$secondary-title-font: 'Gotham-Bold', sans-serif;

$tertiary-title-font: 'Gotham-Book', sans-serif;
$quaternary-title-font: 'Gotham-Medium', sans-serif;
$font-family: 'Gotham-Book', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-cond: 'Gotham-Book', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-browser-safe: Arial, Verdana, sans-serif;
$font-family-icon: 'videoland-v2';
$font-size-default: 1.5rem;
$primary-bg-color: #111111;
$primary-text-color: #fff;
$primary-border-color: #fff;
$secondary-bg-color: #373737;
$secondary-text-color: #888;
$tertiary-bg-color: #303030;
$tertiary-button-color: #eee;
$tertiary-text-color: #111111;
$quaternary-bg-color: #272727;
$quaternary-text-color: #6b6b6b;
$quinary-bg-color: #2e2e2e;
$secondary-title-font-spacing: -0.25pt;
$cobalt: #1e5eac;
$crimson: #ff4a4e;
$emerald: #03a75b;
$flamingo: #f987c5;
$honey: #ffba4d;
$iris: #8d59b1;
$lime: #a0d24a;
$orange: #ff5d00;
$red: #f33;
$slate: #4a90e2;
$dark: #000;
$light: #fff;
$transparent-primary-background-color: rgba($primary-bg-color, 0);
$transparent-primary-background-color-hover: rgba($primary-bg-color, 0.9);
$transparent-secondary-background-color: rgba(255, 255, 255, 0.16);
$transparent-underline-color: rgba($primary-text-color, 0.4);
$kids-bg-color: #f6fbfe;
$kids-6-9-bg-color: #120f3e;
$kids-button-color: #ededed;
$kids-blue-color: #4a90e2;
$kids-success-color: #03a75b;
$kids-info-color: #d2d2d2;
$kids-shadow: 0 5px 0 0;
$facebook-color: #4267b2;
$facebook-color-hover: #3b5998;
$selected-color: #ff3746;
$brand-color: #ff3746;
$toggle-color: #4a90e2;
$error-link-color: #4a90e2;
$cancel-color: #e04b4e;
$toggle-disabled-color: #555;
$onboading-bg-color: #f9f9f9;
$filter-spacing: 3rem;
$cover-spacing: 15px;
$information-color: $cobalt;
$success-color: #17b069;
$delete-color: #b51d04;
$error-color: $orange;
$scrollbar-width: 5px;
$active-border-size: 3px;
$header-height: 4.8rem;
$header-font-size: 1.8rem;
$header-height-mobile: 4.8rem;
$max-number-profile-items: 7;
$default-number-of-strip-items: 2;
$mobile-close-icon-font-size: 28px;
$search-tile-spacing: 1em;
$privacy-bg-color: #1e1e1e;
$notification-information-bg-color: $information-color;
$notification-warning-bg-color: $transparent-secondary-background-color;
$notification-success-bg-color: $success-color;
$notification-error-bg-color: $error-color;
$notification-transparent-bg-color: rgba(255, 255, 255, 0.075);
$notification-transparent-border-color: rgba(255, 255, 255, 0.15);
$icon-button-width: 45px;
$icon-button-height: 45px;
$icon-button-icon-width: 16px;
$icon-button-icon-height: 16px;

$tooltip-bg: #f3f3f3;

$first-breakpoint: 768px;

$header-break: 750px;

$gutter: 20px;
$account-gutter: 2rem;
$account-font-size: 2rem;

$field-bg-color: $light;
$field-color: $dark;
$field-focus-shadow: 0px 2px 13px 2px rgba(255, 255, 255, 0.31);
$field-border-radius: 0.3em;

$breakpoints: (
  phone: 320px,
  tablet: 768px,
  desktop: 1024px,
  header-break: $header-break,
  second-header-break: 1030px,
  kids-header: 450px,
  wide-screen: 1600px,
  wider-screen: 1680px,
  medium: 768px,
  small: 500px,
  extra-small: 340px,
  notification-wide: 650px,
  account-page: 540px
);

$mobile-side-space: 18px;
$bounce-range: 1.3;
$bounce-speed: 1s;
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-15px * $bounce-range);
  }
  60% {
    transform: translateY(-7.5px * $bounce-range);
  }
}
@keyframes bounce--centered {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(-50%);
  }
  40% {
    transform: translateY(-50%) translateY(-15px * $bounce-range);
  }
  60% {
    transform: translateY(-50%) translateY(-7.5px * $bounce-range);
  }
}

/* ONBOARDING */
$rtl-font-regular: $font-family;
$rtl-font-medium: $quaternary-title-font;
$rtl-font-bold: $secondary-title-font;
$font-bebas: $primary-title-font;
$onboarding-primary-text-color: #fff;
$onboarding-primary-border-color: #efefef;
$onboarding-secondary-text-color: rgba(255, 255, 255, 0.5);
$onboarding-tertiary-text-color: #454545;
$onboarding-accent-color: #f9ec35;
$onboarding-success-color: #82cdae;
$onboarding-error-color: $orange;
$onboarding-notification-color: #4a90e2;
$onboarding-scrollbar-color: #7e8289;
$onboarding-button-bg: $brand-color;
$onboarding-button-bg-hover: darken($brand-color, 5%);
$onboarding-button-second-bg: rgba(255, 255, 255, 0.16);
$onboarding-button-second-border-hover: rgba(255, 255, 255, 1);
$onboarding-button-light-border-color: rgba(0, 0, 0, 0.3);
$onboarding-button-light-border-color-hover: #454545;
$onboarding-form-field-focus: rgba(255, 255, 255, 0.3);
@-webkit-keyframes autofill {
  to {
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
  }
}
@keyframes sunset {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 10em;
    opacity: 1;
  }
}
