.svg-icon {
  display: inline-block;

  &--small {
    width: 1.6rem;
    height: 1.6rem;
  }

  &--medium {
    width: 2rem;
    height: 2rem;
  }

  &--large {
    width: 2.4rem;
    height: 2.4rem;
  }

  &_title {
    visibility: hidden;
  }
}
