@import 'variables';
@import './base';

.onboarding-button.icon-button {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: $icon-button-height;
  width: $icon-button-width;
  min-width: 0;
  line-height: 50%;
}

.icon-button__icon {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: $icon-button-icon-height / -2;
  margin-left: $icon-button-icon-width / -2;
}
