@import './base';
@import './variables';

.onboarding-sidebar {
  .help-list {
    margin-top: 15px;
    margin-bottom: 30px;
    list-style-type: none;
    font-size: 14px;
    color: $onboarding-tertiary-text-color;

    li:first-child {
      border-bottom: 0;
    }

    li:last-child {
      border-top: 0;
    }

    &__channel-text {
      font-family: $rtl-font-medium;
      color: $onboarding-tertiary-text-color;
    }

    &__time {
      padding-left: 5px;
      font-family: $rtl-font-regular;
    }

    &__list-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 4em;
      border: 1px solid $onboarding-primary-border-color;
      border-left: 0 solid;
      border-right: 0 solid;
    }

    &__list-icon {
      margin-right: 1em;
    }
  }
}
