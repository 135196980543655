@import './base';

.email-verified__summary-change-link {
  margin: 1.2em 0 0;
  line-height: 40px;
  display: inline-block;
}

.email-verified__proceed-button {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.email-verified__tiers-display,
.email-verified-tier-summary {
  margin-top: 40px;
}

.email-verified-tier-summary {
  max-width: 600px;
  .tier__period:not(.tier__period--mobile) {
    display: inline;
  }
  .tier__period-info {
    display: block;
    width: 100%;
  }
}

.onboarding-email-verified {
  .onboarding-progress {
    max-width: 335px;
    margin: 0 auto;
  }
}

.tierdetails {
  display: flex;
  min-height: 117px;
  margin: 0;
  align-items: center;
  background-color: #373737;
  border: 4px solid #373737;
  border-radius: 10px;
  color: #fff;
  display: block;
  display: flex;
  font-size: 11px;
  justify-content: center;
  margin: 0 20px;
  max-width: 1080px;
  padding: 20px;
  position: relative;
  &__name {
    flex: 1;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    font-family: $quaternary-title-font;
  }
  &__features {
    flex: 3;
    font-size: 14px;
    padding: 0 20px 0 40px;
  }
  &__price-period {
    display: flex;
    flex: 2;
    flex-direction: row;
    font-size: 16px;
    justify-content: center;
    text-align: center;
    color: #fff;
    font-family: $quaternary-title-font;
  }
  &__price {
    padding: 0 5px;
  }
  &__period {
    color: #888;
  }
  @include media('<desktop') {
    &__features {
      padding: 0 0 0 20px;
    }
    &__price-period {
      display: block;
    }
  }
}
