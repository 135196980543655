@import './base';

.full-page-message {
  .onboarding__heading {
    margin-top: 5.5rem;

    @include media('>=desktop') {
      margin-top: 8rem;
    }
  }

  .onboarding__image {
    display: block;
    margin: 5rem auto 2rem;
    width: 100px;

    @include media('>=desktop') {
      margin-top: 10rem;
    }
  }

  a:not(.onboarding-button) {
    color: inherit;
    text-decoration: underline;
  }
}

.full-page-message__heading {
  word-break: break-word;
}

.full-page-message--with-image {
  .onboarding__heading {
    margin-top: 2.5rem;

    @include media('>=desktop') {
      margin-top: 0;
    }
  }
}
