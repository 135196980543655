.layout {
  > .app-content {
    min-height: 100vh;
    margin-bottom: -60px;
    &-tvgemist {
      background-image: url('../../../../static/images/tv-gemist/finalize.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &__push {
    height: 60px;
  }
}
