@import 'variables';
@import './base';

.payment-reminder__is-sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: $gutter;
  max-height: 80vh;
  overflow: auto;
  z-index: 1001; // above help button

  @include media('>notification-wide', '<desktop') {
    padding-bottom: 16rem;
  }

  @include media('<notification-wide') {
    padding-bottom: 25rem;
  }

  @function getNotificationWidthByDevice($device) {
    $width: map-get($breakpoints, $device);
    $notification-width: #{$width - ($gutter * 2)};
    @return $notification-width;
  }

  .notification {
    margin-bottom: 3rem;
    z-index: 1001; // above help button
    box-shadow: 0px 20px 20px -8px $primary-bg-color;

    $notification-desktop-width: getNotificationWidthByDevice(desktop);
    margin-left: calc(#{$notification-desktop-width} / -2);
    width: $notification-desktop-width;
    left: 50%;

    &:last-child {
      margin-bottom: 0;
    }

    @include media('>=notification-wide') {
      display: flex;
    }

    .notification__actions {
      display: flex;
      white-space: nowrap;
    }

    @include media('<desktop') {
      margin-left: $gutter;
      width: calc(100vw - #{2 * $gutter});
      left: 0;
    }
  }
}
